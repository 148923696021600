@use 'styles/includes' as *;

.CtaSlim {
    $root: &;

    @extend %container;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__Container {
        padding: 2rem 3rem;
        border: 1px solid $black;
        display: grid;
        gap: 4rem;
        position: relative;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        @media (hover: hover) {
            &:hover {
                color: $white;
                background-color: $black;
            }
        }

        @include media(m) {
            padding: 2rem 2rem 2rem 4rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }

        @include media(l) {
            grid-template-columns: 2fr 1fr;
        }

        @include media(xxl) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__Link {
        @extend %card-link;
    }

    &__Title {
        font-family: $font-family-bold;
        font-weight: $font-weight-regular;
        font-size: 3.2rem;
        line-height: 100%;
        text-align: center;

        @include media(m) {
            text-align: left;
        }
    }

    &__ButtonContainer {
        @include media(m) {
            width: auto;
            margin-left: auto;
            display: inline;
        }
    }

    &__Button {
        padding: 2rem 3.2rem;
        width: 100%;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: 100%;
        text-align: center;
        color: $white;
        background-color: $black;
        border: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: relative;
        z-index: 0;
        transition: color $transition-ease-out-expo;

        @include media(m) {
            padding: 2.2rem 3.2rem;
            width: auto;
            font-size: 2rem;
        }

        &::after {
            content: '';
            width: 0;
            height: 100%;
            background-color: $violet;
            position: absolute;
            inset: 0;
            z-index: -1;
            transition: width $transition-ease-out-expo;
        }
        
        @media (hover: hover) {
            #{$root}__Container:hover & {
                color: $black;

                &::after {
                    width: 100%;
                }
            }
        }
    }
}
