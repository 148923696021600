@use 'styles/includes' as *;

.MarqueeText {
    $root: &;

    padding: 0.4rem 0;
    min-height: 34px;
    background-color: $violet;

    &__Text {
        font-family: $font-family-semibold;
        font-weight: $font-weight-semibold;
        font-size: 2rem;
        line-height: 130%;
        letter-spacing: 0;
        color: $black;
    }
}
