@use 'styles/includes' as *;

.FadeIn {
    $root: &;
    opacity: 0;
    
    &--Visible {
        animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation-delay: 200ms;
    }
}
